<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal(name="formBankAdd" @submit.prevent="submit")
                .form-group
                    label.col-sm-4.control-label.star-required(for="value") {{'register.expense.bank.form.VALUE'|translate}}
                    .col-sm-8
                        OptiNumber#value(
                            :header="'register.expense.current.form.VALUE'|translate"
                            :value="form.value"
                            :float="true"
                            :required="true"

                            @confirmed="value => form.value = value") {{form.value|currency}}
                        OptiValidate(f="value" :data="$v.form.value")

                .form-group
                    label.col-sm-4.control-label.star-required(for="description") {{'register.expense.bank.form.DESCRIPTION'|translate}}
                    .col-sm-8
                        textarea#description.form-control(v-model="form.description" name="description" rows="5" required)
                        OptiValidate(f="description" :data="$v.form.description")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="$v.form.$invalid")
        .col-md-offset-2.col-md-8
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'
    import {noZero} from '@/js/vue.validators.js'

    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiRegisterExpenseBankAdd',
        components: {
            OptiNumber,
            OptiDimmer,
            OptiValidate,
            OptiButtonSave
        },
        data() {
            return {
                form: {
                    value: 0,
                    description: ''
                },

                loading: false
            }
        },
        validations: {
            form: {
                value: {
                    noZero
                },
                description: {
                    required
                }
            }
        },
        methods: {
            submit() {
                this.loading = true

                API.post('register/expenses/bank/add', this.form).then(() => {
                    this.$notify.success('register.expense.bank.notify.SAVE')

                    this.$state.go('app.register.current')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('register.expense.bank.notify.error.SAVE')
                })
            }
        }
    }
</script>