<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.patient.HEADING'|translate}}

        .row
            .col-sm-6
                .panel-body(style="padding:0px;padding-top:15px")
                    .wrapper
                        .alert.alert-warning.text-center(v-if="!settings.lastActivity.length")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'settings.patient.notify.NODATA'|translate}}

                    form.form-horizontal(name="settingsForm" @submit.prevent="submit")
                        .wrapper
                            .form-group(v-if="settings.lastActivity.length" v-for="(setting, index) in settings.lastActivity" :key="index")
                                .col-xs-3
                                    input.form-control.text-right(v-model="setting.time" type="number" :name="'time' + index" required)
                                .col-xs-6
                                    select.form-control(v-model="setting.period" :name="'period' + index" required)
                                        option(v-for="period in periods" :value="period"
                                            ) {{translatePeriod(setting.time, period)|translate}}
                                .col-xs-3.text-center
                                    button.btn.btn-danger(type="button" @click="removePeriod(index)")
                                        span.fa.fa-remove

                        .panel-footer.clearfix
                            OptiButton(type="purple" :disabled="$v.settings.$invalid" @click.native="addPeriod"
                                ) {{'settings.patient.ADDPERIOD'|translate}}
                                template(#icon)
                                    i.fa.fa-clock-o

                            OptiButtonSave.pull-right(type="submit" :disabled="$v.settings.$invalid")

            .col-sm-6
                .panel-body
                    i.fa.fa-question-circle.mr3
                    span {{'settings.patient.info.HEADING'|translate}}

                    ul(style="list-style-type:square;padding-top:5px")
                        li(v-for="i in info") {{'settings.patient.info.option.OPTION' + i|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, minValue} from 'vuelidate/lib/validators'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsPatients',
        components: {
            OptiButton,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                periods: _.range(1, 3 + 1),
                info: _.range(1, 12 + 1),
                settings: {
                    lastActivity: []
                },

                loading: false
            }
        },
        mounted() {
            this.loading = true

            API.get('settings/department').then(res => {
                const {lastActivity} = res.data
                this.settings.lastActivity = lastActivity

                this.$v.$reset()
            }).finally(() => {
                this.loading = false
            }).catch(() => {
              this.$notify.error('default.notify.error.DEFAULT')
            })
        },
        validations() {
            return {
                settings: {
                    lastActivity: {
                        $each: {
                            time: {
                                required,
                                minValue: minValue(1)
                            },
                            period: {
                                required
                            }
                        }
                    }
                }
            }
        },
        methods: {
            submit() {
                this.loading = true

                API.post('settings/patients/period/save', this.settings).then(() => {
                    this.$notify.success('settings.patient.notify.SUCCESS')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('default.notify.error.DEFAULT')
                })
            },
            addPeriod() {
                this.settings.lastActivity.push({
                    time: null,
                    period: null
                })
            },
            removePeriod(index) {
                this.settings.lastActivity.splice(index, 1)
            },
            translatePeriod(time, period) {
                switch(period) {
                    case 1:
                        return time === 1 ? 'default.day.ONE' : 'default.day.MORE'

                    case 2:
                        if(time === 1) {
                            return 'default.week.ONE'
                        } else if (2 <= time && time < 5) {
                            return 'default.week.MIDDLE'
                        }

                        return 'default.week.MORE'

                    case 3:
                        if(time === 1) {
                            return 'default.month.ONE'
                        } else if(2 <= time && time < 5) {
                            return 'default.month.MIDDLE'
                        }

                        return 'default.month.MORE'
                }
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
</style>