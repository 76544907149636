<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formTraining" @submit.prevent="addTraining")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'user.training.table.TYPE'|translate}}
                    .col-sm-8
                        select#type.form-control(name="type" v-model="training.type" @change="updateType")
                            option(v-for="training_type in select_types" :value="training_type.id") {{training_type.name}}
                        OptiValidate(f="type" :data="$v.training.type")
                .form-group(style="padding-bottom:15px")
                    label.col-sm-4.control-label.star-required(for="name") {{'user.training.table.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(name="name" v-model="training.name" required)
                        OptiValidate(f="name" :data="$v.training.name")

                .form-group
                    label.col-sm-4.control-label.star-required(for="date_from") {{'user.training.table.date.FROM'|translate}}
                    .col-sm-8
                        OptiDate#date_from(:value="training.date_from" @update-date="d => training.date_from = d")
                        OptiValidate(f="date_from" :data="$v.training.date_from")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_to") {{'user.training.table.date.TO'|translate}}
                    .col-sm-8
                        OptiDate#date_to(:value="training.date_to" @update-date="d => training.date_to = d")
                        OptiValidate(f="date_to" :data="$v.training.date_to")

                .form-group
                    label.col-sm-4.control-label.star-required(for="date_from_start") {{'user.training.table.date.hours.FROM'|translate}}
                    .col-sm-8
                        .row
                            .col-xs-5
                                select#date_from_start.form-control(name="date_from_start" v-model="training.date_from_start")
                                    option(v-for="hour in hours" v-bind:value="hour") {{hour}}
                            .col-xs-2.bold.text-center(style="padding-top:5px") :
                            .col-xs-5
                                select.form-control(name="date_from_end" v-model="training.date_from_end")
                                    option(v-for="minute in minutes" v-bind:value="minute") {{minute}}
                .form-group(style="padding-bottom:15px")
                    label.col-sm-4.control-label.star-required(for="date_to_start") {{'user.training.table.date.hours.TO'|translate}}
                    .col-sm-8
                        .row
                            .col-xs-5
                                select#date_to_start.form-control(name="date_to_start" v-model="training.date_to_start")
                                    option(v-for="hour in hours" v-bind:value="hour") {{hour}}
                            .col-xs-2.bold.text-center(style="padding-top:5px") :
                            .col-xs-5
                                select.form-control(name="date_from_end" v-model="training.date_to_end")
                                    option(v-for="minute in minutes" v-bind:value="minute") {{minute}}

                .form-group(style="padding-bottom:15px")
                    label.col-sm-4.control-label.star-required(for="max") {{'user.training.table.MAX'|translate}}
                    .col-sm-8
                        OptiNumber#max(:header="'user.training.table.MAX'|translate" :value="training.max"
                            @confirmed="m => training.max = m" :required="true") {{training.max}}
                        OptiValidate(f="max" :data="$v.training.max")

                .form-group(style="padding-bottom:15px" v-show="$uac.permission('trainings.cost')")
                    label.col-sm-4.control-label.star-required(for="cost") {{'user.training.table.COST'|translate}}
                    .col-sm-8
                        OptiNumber#cost(:header="'user.training.table.COST'|translate" :value="training.cost"
                            @confirmed="c => training.cost = c" :required="true" :float="true") {{training.cost}}
                        OptiValidate(f="cost" :data="$v.training.cost")

                .form-group
                    label.col-sm-4.control-label.star-required(for="comments") {{'user.training.table.COMMENTS'|translate}}
                    .col-sm-8
                        AngularComponent#comments(:component="comments")
                        OptiValidate(f="comments" :data="$v.training.comments")

                .form-group
                    label.col-sm-4.control-label(for="users") {{'user.training.USERS'|translate}}
                    .col-sm-8
                        .form-control-static {{training.users.length}}

                .form-group
                    .col-xs-12
                        OptiCheckboxUser#users(
                            :ids="training.users"
                            @user-ids="userIds")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.training.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
import {API} from '@/js/app/vue/api'
import {required, minValue} from 'vuelidate/lib/validators'
import {noZero, isNumeric} from '@/js/vue.validators.js'

import AngularComponent from '@/js/app/_bridge/components/AngularComponent.vue'
import OptiSelectEnum from '../../Select/OptiSelectEnum'
import OptiDate from '@/js/app/vue/components/OptiDate'
import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
import OptiCheckboxUser from '@/js/app/vue/components/Checkbox/OptiCheckboxUser'
import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
import OptiValidate from '@/js/app/vue/components/OptiValidate'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

export default {
    name: 'OptiUsersTrainingAdd',
    components: {
        AngularComponent,
        OptiSelectEnum,
        OptiDate,
        OptiNumber,
        OptiCheckboxUser,
        OptiButtonSave,
        OptiValidate,
        OptiDimmer
    },
    data() {
        let hours = []
        for (let i = 0; i < 24; ++i) {
            hours.push(i < 10 ? '0' + i : i.toString())
        }

        return {
            hours: hours,
            minutes: ['00', '30'],

            select_types: [],

            training: {
                type: 0,
                name: '',

                date_from: '',
                date_to: '',

                date_from_start: '09',
                date_from_end: '00',
                date_to_start: '17',
                date_to_end: '00',

                max: 0,
                cost: 0,
                comments: '',

                users: []
            },

            loading: false
        }
    },
    validations: {
        training: {
            type: {
                noZero
            },
            name: {
                required
            },
            date_from: {
                required
            },
            date_to: {
                required
            },
            max: {
                noZero
            },
            comments: {
                required
            },
            cost: {
                noZero,
                isNumeric,
                minValue: minValue(0)
            }
        }
    },
    mounted() {
        API.get('dictionary/training-type/select').then(res => {
            this.select_types = res.data
        }).catch(() => {
            this.$notify.error('dictionary.trainingtype.notify.error.LIST')
        })
    },
    methods: {
        addTraining() {
            this.loading = true

            API.post('trainings/add', this.training).then(() => {
                this.$state.go('app.users.trainings.schedule')

                this.$notify.success('user.training.notify.ADD')
            }).catch(() => {
                this.$notify.error('user.training.notify.error.ADD')

                this.loading = false
            })
        },

        userIds(ids) {
            this.training.users = ids
        },

        updateType() {
            let training_type = this.select_types.filter(s => s.id === this.training.type)[0]

            this.training.name = training_type.name
            this.training.max = training_type.max
            this.training.comments = training_type.comments
        }
    },
    computed: {
        comments() {
            let vm = this

            return {
                template: `<textarea name="comments" ng-wig="$ctrl.comments"></textarea>`,
                $ctrl: {
                    get comments() {
                        return vm.training.comments
                    },
                    set comments(val) {
                        vm.training.comments = val
                    }
                }
            }
        }
    }
}
</script>