<template lang="pug">
    div
        OptiSettingsCashRegisterTable(v-show="mode === 'table'"
            :cashRegisters="cashRegisters"

            @load="load"
            @set-mode="setMode")

        OptiSettingsCashRegisterAdd(v-show="mode === 'add'"
            :cashRegisters="cashRegisters"

            @load="load"
            @set-mode="setMode")

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSettingsCashRegisterTable from '@/js/app/vue/components/Settings/CashRegister/OptiSettingsCashRegisterTable'
    import OptiSettingsCashRegisterAdd from '@/js/app/vue/components/Settings/CashRegister/OptiSettingsCashRegisterAdd'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsCashRegister',
        components: {
            OptiButton,
            OptiSettingsCashRegisterTable,
            OptiSettingsCashRegisterAdd,
            OptiDimmer
        },
        data() {
            return {
                mode: 'table',
                cashRegisters: [],

                loading: false
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('register/cash-register').then((res)=>{
                    this.cashRegisters = res.data
                }).catch(()=>{
                    this.$notify.error('settings.cash.notify.error.LIST')
                }).finally(()=>{
                    this.loading = false
                })
            },

            setMode(mode) {
                this.mode = mode
            }
        },
    }
</script>