<template lang="pug">
    .row
        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'sale.offstock.header.SALEOFFSTOCK'|translate}}

                        .pull-right \#{{saleoffstock.id}}

                    .row
                        .col-sm-6 {{'sale.offstock.table.head.NAME'|translate}}
                        .col-sm-6 {{saleoffstock.name}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.MFCT'|translate}}
                        .col-sm-6 {{saleoffstock.producent}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.PRICE'|translate}}
                        .col-sm-6 {{saleoffstock.price|currency}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.QUANTITY'|translate}}
                        .col-sm-6 {{saleoffstock.quantity}}
                    .row(v-show="saleoffstock.vatId")
                        .col-sm-6 VAT
                        .col-sm-6 {{saleoffstock.vatName|translate}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.VALUE'|translate}}
                        .col-sm-6 {{saleoffstock.quantity * saleoffstock.price|currency}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.DISCOUNT'|translate}}
                        .col-sm-6
                            span {{saleoffstock.quantity * saleoffstock.price - saleoffstock.valueAfterDiscount|currency}}
                            span ({{saleoffstock.percentDiscount|currency}}%)
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.WITHDISCOUNT'|translate}}
                        .col-sm-6 {{saleoffstock.valueAfterDiscount|currency}}
                    .row(v-if="notice && 'all' in notice")
                        .col-sm-6(style="font-size:85%")
                            OptiReceiptNotice(:data="notice.all")
                        .col-sm-6
                            OptiReceiptNoticeValue(:data="notice.all")
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.DATE'|translate}}
                        .col-sm-6 {{saleoffstock.date}}
                    .row
                        .col-sm-6 {{'sale.offstock.table.head.SALER'|translate}}
                        .col-sm-6 {{saleoffstock.user.firstName}} {{saleoffstock.user.lastName}}
                    OptiPaymentShow(:payments="saleoffstock.payment" :type-id="saleoffstock.paymentTypeId"
                        :type-name="saleoffstock.paymentTypeName" :differences="notice.differences")
                    .row(v-if="saleoffstock.receipt")
                        .col-sm-6 {{'sale.offstock.table.head.NORECEIPT'|translate}}
                        .col-sm-6 {{saleoffstock.receipt|receipt}}
                    .row(v-if="saleoffstock.patient.id")
                        .col-sm-6 {{'sale.offstock.table.head.PATIENT'|translate}}
                        .col-sm-6
                            OptiPatient(:data="saleoffstock.patient")
                    .row(v-if="saleoffstock.description")
                        .col-sm-6 {{'sale.offstock.table.head.COMMENTS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(saleoffstock.description)")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
    import OptiPaymentShow from '@/js/app/vue/components/Payments/OptiPaymentShow'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSaleOffstockDetails',
        components: {
            OptiReceiptNotice,
            OptiReceiptNoticeValue,
            OptiPaymentShow,
            OptiPatient,
            OptiDimmer
        },
        data() {
            return {
                saleoffstock: {},
                notice: {},

                loading: true
            }
        },
        mounted() {
            API.get('sale/offstock/' + this.$state.params.id).then(res => {
                this.saleoffstock = res.data

                this.getNotice('saleoffstock', this.$state.params.id).then(noticeData => {
                    this.notice = noticeData
                })

                if (this.saleoffstock.price * this.saleoffstock.quantity !== 0) {
                    this.saleoffstock.percentDiscount =
                        Math.ceil((this.saleoffstock.quantity * this.saleoffstock.price - this.saleoffstock.valueAfterDiscount) /
                            (this.saleoffstock.price * this.saleoffstock.quantity) * 10000) / 100
                } else {
                    this.saleoffstock.percentDiscount = 0
                }
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('sale.offstock.notify.error.LOAD')
            })
        },
        methods: {
            getNotice(module, id) {
                return API.post('register/notice', { module, id }).then(res => {
                    if(res.data.all) {
                        return res.data
                    }

                    return {}
                }).catch(() => {
                    this.$notify.error('register.notify.error.NOTGETCHANGE')

                    return {}
                })
            }
        }
    }
</script>