<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:10px")
            OptiButton(@click.native="$emit('set-mode', 'table')") {{'default.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

        .col-md-offset-2.col-md-8
            form.form-horizontal(@submit.prevent="cashRegisterSave")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'settings.cash.table.NAME'|translate}}
                    .col-sm-8
                        input.form-control(
                            id="name"
                            name="name"
                            v-model="cashRegister.name"
                            :class="{'its-error': $v.cashRegister.name.$error}"

                            @blur="$v.cashRegister.name.$touch()")

                        OptiValidate(f="name" :data="$v.cashRegister.name")

                .form-group
                    label.col-sm-4.control-label.star-required(for="code") {{'settings.cash.table.CODE'|translate}}
                    .col-sm-8
                        input.form-control(
                            id="code"
                            name="code"
                            v-model="cashRegister.code"
                            :class="{'its-error': $v.cashRegister.code.$error}"

                            @blur="$v.cashRegister.code.$touch()")

                        OptiValidate(f="code" :data="$v.cashRegister.code")

                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(type="submit" :disabled="$v.$invalid")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required, maxLength} from 'vuelidate/lib/validators'
    import {unique} from '@/js/vue.validators.js'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiSettingsCashRegisterAdd',
        props: {
            cashRegisters: {
                type: Array,
                required: true,
            }
        },
        components: {
            OptiButton,
            OptiValidate,
            OptiButtonSave
        },
        data() {
            return {
                cashRegister: {
                    name: '',
                    code: '',
                },
            }
        },
        validations: {
            cashRegister: {
                name: {
                    required,
                    maxLength: maxLength(5),
                    unique: unique('cash_registers', 'name'),
                },
                code: {
                    required,
                    unique: unique('cash_registers', 'code'),
                },
            },
        },
        methods: {
            cashRegisterSave() {
                API.post('register/cash-register', this.cashRegister).then(() => {
                    this.$emit('set-mode', 'table')
                    this.$emit('load')

                    this.$notify.success('settings.cash.notify.SAVE')
                }).catch(() => {
                    this.$notify.error('settings.cash.notify.error.SAVE')
                })
            },
        },
    }
</script>
