<template lang="pug">
    .row
        .col-xs-12.text-right(style="margin-bottom:10px")
            OptiButton(type="success" @click.native="$emit('set-mode', 'add')") {{'settings.cash.ADD'|translate}}
                template(#icon)
                    i.fa.fa-plus

        .col-xs-12
            .table-responsive
                table.table.table-striped.middle
                    thead
                        tr
                            th #
                            th {{'settings.cash.table.ID'|translate}}
                            th {{'settings.cash.table.NAME'|translate}}
                            th {{'settings.cash.table.CODE'|translate}}
                            th {{'settings.cash.table.DATE'|translate}}
                            th {{'settings.cash.table.ACTIVE'|translate}}
                            th {{'settings.cash.table.DEFAULT'|translate}}

                    tbody
                        tr(v-for="(cashRegister, index) in cashRegisters" :key="cashRegister.id")
                            td {{cashRegisters.length - index}}
                            td {{cashRegister.id}}
                            td {{cashRegister.name}}
                            td {{cashRegister.code}}
                            td {{cashRegister.created_at|noYearSek}}
                            td
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    label
                                        input(type="checkbox" v-model="cashRegister.active" name="active"
                                            @change="setActiveCashRegister(cashRegister.id, cashRegister.active)")
                                        span.fa.fa-check
                            td
                                .checkbox.c-checkbox.checkbox-primary.inline
                                    label
                                        input(type="checkbox" v-model="cashRegister.default" name="default"
                                            @change="setDefaultCashRegister(cashRegister.id)")
                                        span.fa.fa-check

                        tr.text-muted.text-center(v-show="!cashRegisters.length")
                            td(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiSettingsCashRegisterTable',
        props: {
            cashRegisters: {
                type: Array,
                required: true,
            }
        },
        components: {
            OptiButton
        },
        methods: {
            setActiveCashRegister(id, active) {
                API.post('register/cash-register/active/save', {
                    id: id,
                    active: active
                }).then(() => {
                    this.$emit('load')

                    this.$notify.success( 'settings.cash.notify.ACTIVE')
                }).catch(() => {
                    this.$notify.error('settings.cash.notify.error.ACTIVE')
                })
            },
            setDefaultCashRegister(id) {
                API.post('register/cash-register/default/save', {
                    id: id
                }).then(()=> {
                    this.$emit('load')

                    this.$notify.success('settings.cash.notify.DEFAULT')
                }).catch(() => {
                    this.$notify.error('settings.cash.notify.error.DEFAULT')
                })
            }
        },
    }
</script>