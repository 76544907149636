import {API} from '@/js/app/vue/api'
import {helpers} from 'vuelidate/lib/validators'
import {PESEL} from '@/js/app/vue/helpers/PESEL'

function noZero(value) {
    return value && value !== '0'
}

function noZeroIf(bool) {
    return helpers.withParams({
        bool: bool
    }, function(value, parent) {
        let ref = helpers.ref(bool, this, parent) //potrzebne dla OptiPayment.vue

        if(typeof ref === 'undefined') {
            ref = bool
        }

        return ref ? noZero(value) : true
    })
}

function minLengthIf(bool, length) {
    return helpers.withParams({
        bool: bool,
        min: length
    }, function(value, parent) {
        let ref = helpers.ref(bool, this, parent) //potrzebne dla OptiPayment.vue

        if(typeof ref === 'undefined') {
            ref = bool
        }

        return ref ? value.toString().length >= length : true
    })
}

function equalLength(length) {
    return helpers.withParams({
        length: length
    }, value => typeof value !== 'undefined' && value.length === length)
}

function equalLengthIf(bool, length) {
    return helpers.withParams({
        bool: bool,
        length: length
    }, function(value, parent) {
        let ref = helpers.ref(bool, this, parent) //potrzebne dla OptiPayment.vue

        if(typeof ref === 'undefined') {
            ref = bool
        }

        return ref ? value.toString().length === length : true
    })
}

function betweenLength(min, max) {
    return helpers.withParams({
        min: min,
        max: max
    }, value => !value || (min <= value.length && value.length <= max))
}

function unique(table_name, table_column, except_id, table_except_id_column) {
    let timeout = null

    return async value => {
        if(timeout) {
            clearTimeout(timeout)
        }

        if(!value) {
            return true
        }

        return new Promise((resolve, reject) => {
            if(timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                API.post('validate/dbmatch', {
                    data: _.trimEnd([table_name, table_column, except_id, table_except_id_column].join(','), ','),
                    compare: value
                }).then(res => {
                    resolve(res.data.info === 'ok')
                }).catch(() => {
                    reject('Jest błąd przy sprawdzaniu danych czy istnieją!')
                })
            }, 350 + Math.random() * 300)
        })
    }
}

function barcode(product_barcode) {
    let timeout = null

    return async function(value) {
        if(timeout) {
            clearTimeout(timeout)
        }

        if(!value || ([12, 13].includes(value.length) && product_barcode.startsWith(value))) {
            return true
        }

        return new Promise((resolve, reject) => {
            if(timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                API.get('validate/barcode/' + value).then(res => {
                    resolve(!res.data.exists)
                }).catch(() => {
                    reject('Jest błąd przy sprawdzaniu kodu kreskowego!')
                })
            }, 350 + Math.random() * 300)
        })
    }
}

function unique_lp(product_id) {
    let timeout = null

    return async function(value) {
        if(timeout) {
            clearTimeout(timeout)
        }

        if(!value) {
            return true
        }

        return new Promise((resolve, reject) => {
            if(timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
                API.get('validate/product_lp/' + product_id + '/' + value).then(res => {
                    resolve(!res.data.exists)
                }).catch(() => {
                    reject('Jest błąd przy sprawdzaniu lp produktu!')
                })
            }, 350 + Math.random() * 300)
        })
    }
}

function pesel(value) {
    return PESEL.validate(value)
}

function dateCompare(operator, date_compare) {
    return helpers.withParams({
        operator: operator,
        date_compare: date_compare
    }, value => {
        if (!value || !date_compare) {
            return true
        }

        switch(operator) {
            case 'lt':
                return moment(value).isBefore(date_compare)

            case 'lte':
                return moment(value).isSameOrBefore(date_compare)

            case 'gt':
                return moment(value).isAfter(date_compare)

            case 'gte':
                return moment(value).isSameOrAfter(date_compare)
        }

        return false
    })
}

function dateTodayOrPast(today) {
    return helpers.withParams({
        today: today
    }, value => {
        if(!value) {
            return true
        }

        let date = moment(value),
            dateToday = moment(),
            isToday = date.isSame(dateToday, 'day')

        return (today && isToday) || (date.isBefore(dateToday) && !isToday)
    })
}

function dateTodayOrFuture(today) {
    return helpers.withParams({
        today: today
    }, value => {
        if(!value) {
            return true
        }

        let date = moment(value),
            dateToday = moment()

        return (today && date.isSame(dateToday, 'day')) || date.isAfter(dateToday)
    })
}

function isNumeric(num){
    return !isNaN(num)
}

export {noZero, noZeroIf, minLengthIf, equalLength, equalLengthIf, betweenLength, unique, barcode, unique_lp, pesel,
    dateCompare, dateTodayOrPast, dateTodayOrFuture, isNumeric}