<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal.form-validate(name="formCoupon" @submit.prevent="addCoupon")

                .form-group(style="padding-bottom:15px")
                    label.col-sm-4.control-label.star-required(for="id") {{'coupons.table.ID'|translate}}
                    .col-sm-8
                        input#id.form-control(name="id" v-model="coupon.id" required)
                        OptiValidate(f="id" :data="$v.coupon.id")

                .form-group
                    label.col-sm-4.control-label.star-required(for="department_salon") {{'schedule.DEPARTMENT'|translate}}
                    .col-sm-8
                        OptiSelectDepartment#department_salon(
                            :department-id="coupon.salon_id"
                            :simple="true"
                            :show-all="true"
                            @selected="(department) => coupon.salon_id = department.id")
                        OptiValidate(f="id" :data="$v.coupon.salon_id")

                .form-group
                    label.col-sm-4.control-label.star-required(for="value") {{'coupons.table.VALUE'|translate}}
                    .col-sm-8
                        select#value.form-control(name="value" v-model="coupon.value")
                            option(v-for="couponValue in [100, 200, 500]" :value="couponValue") {{couponValue}}
                        OptiValidate(f="value" :data="$v.coupon.value")

                .form-group
                    label.col-sm-4.control-label.star-required(for="expiration_date") {{'coupons.table.EXPIRATION'|translate}}
                    .col-sm-8
                        OptiDate#expiration_date(:value="coupon.expiration_date"
                            @update-date="d => coupon.expiration_date = d")
                        OptiValidate(f="expiration_date" :data="$v.coupon.expiration_date")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.coupon.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {unique} from '@/js/vue.validators'
    import {required, maxLength, minLength, numeric} from 'vuelidate/lib/validators'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'

    export default {
        name: 'OptiCouponsAdd',
        components: {
            OptiValidate,
            OptiDate,
            OptiSelectDepartment,
            OptiButtonSave,
            OptiDimmer,
        },
        data() {
            return {
                coupon: {
                    salon_id: '',
                    id: '',
                    value: '',
                    expiration_date: '2100-01-01'
                },

                loading: false
            }
        },
        validations: {
            coupon: {
                id: {
                    numeric,
                    required,
                    unique: unique('coupons', 'id'),
                    minLength: minLength(12),
                    maxLength: maxLength(12),
                },
                salon_id: {
                    required
                },
                value: {
                    required
                },
                expiration_date: {
                    required
                }
            }
        },
        methods: {
            addCoupon() {
                if (moment(this.coupon.expiration_date).startOf('day').isBefore(moment().startOf('day'))) {
                    this.$notify.error('coupons.validate.EXPBEFOREDATE')
                    return
                }

                this.loading = true

                API.post('coupons/resource', this.coupon).then(() => {
                    this.$notify.success('coupons.notify.SAVE')

                    this.$state.go('app.coupons.table')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('coupons.notify.error.SAVE')
                })
            }
        }
    }
</script>